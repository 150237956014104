<template>
  <va-card title="Multicast Domain">
    <div>
      <div>
        <form><br>
          <va-input
            v-model="multicast_domain"
            type="text"
            label="Domain"
            placeholder="Enter domain name"
            :error="!!MDErrors.length"
            :error-messages="MDErrors"
          />
          <div class="d-flex justify--center mt-3">
            <va-button v-if="!multicastFlag" type="submit" class="my-0" @click.prevent="mdCall('create')">Create</va-button>
            <va-button v-if="multicastFlag" type="submit" class="my-0" @click.prevent="mdCall('update')">Update</va-button>
          </div>
        </form>
      </div>
    </div>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'config',
  data () {
    return {
      multicast_domain: '',
      MDErrors: [],
      multicast_id: '',
      multicastFlag: false
    }
  },
  computed: {
    formReady () {
      return !this.MDErrors.length
    },
  },
  created () {
    this.getMulticastDomainInfo();
  },
  methods: {
    getMulticastDomainInfo () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'multicast').then(res => {
        loader.hide()
        if(res.body.length > 0){
          this.multicastFlag = true
          var vm = this
          res.body.map(function(data){
            vm.multicast_domain = data.multicast
            vm.multicast_id = data.id
          })
        }else{
          this.multicastFlag = false
          this.multicast_domain = ''
        }
      })
    },
    mdCall (type) {
      this.MDErrors = this.multicast_domain ? [] : ['Domain name is required']
      
      if (!this.formReady) { 
        return 
      }

      var payload = { 
        multicast : this.multicast_domain
      }

      if(type == 'create'){
        var requestURL = this.$http.post(config.menu.host + 'multicast', payload)
      }else{
        var requestURL = this.$http.put(config.menu.host + 'multicast/' + this.multicast_id, payload)
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      requestURL.then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({text: response.body,type: 'success'})
        }
        this.getMulticastDomainInfo()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({text: error.body,type: 'error'})
        }
      })
    },
  },
}
</script>
<style lang="scss">
</style>